import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import './EditStyle.css'
import { Edit } from "@mui/icons-material";
import { AlertContext } from "contexts/AlertContext";
import { editBucket, editCaptionFeatured } from "./actions";

import './EditStyle.css'
const SelectActiveBucket = (props) => {
    
  const [editMode, setEditMode] = useState(false);
  const [bucketId, setBucketID] = useState(props.value==="Active"?1:0);
  const [pageName, setPageName] = useState(props.value);
  const [langList, setLangList] = useState([
    {
        "id": 1,
        "name": "Active"
    },
    {
        "id": 0,
        "name": "Inactive"
    }
]);

const { showAlert } = useContext(AlertContext);
const handleStatusUpdate = async (isActive) => {
  const { data, error } = await editBucket(props.post_id, isActive);
  if(data){
    showAlert("Updated Sucessfully!", "success");
    for( let i = 0; i< langList.length; i++){
      if(langList[i].id === isActive){
        setPageName(langList[i].name)
      }
  }
  }
  if(error){
    showAlert("Something Went Wrong!", "error");
  }
  setEditMode(false);
};

  return (
    <>
    {!editMode ? (
       <div
       className="language-display"
       onClick={() => {
           setEditMode(true);
       }}
   >
       {pageName}
       <Edit className="edit-icon" />
   </div>
) : (
      <FormControl>
           <InputLabel></InputLabel>
      <Select
        value={bucketId}
        onChange={(e) => {
         
          setBucketID(e.target.value);
        handleStatusUpdate(e.target.value);      
        }}
        label=""
        
      >
        <MenuItem key={-1} value={-1}>
          Select
        </MenuItem>
        {langList.map((lang, index) => {
          
            return (
              <MenuItem key={lang.id} value={lang.id}>
                {lang.name}
              </MenuItem>
            );
          
        })}
      </Select>
      </FormControl>
    )}
  </>
);}
export default SelectActiveBucket
