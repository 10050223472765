// packages
import AXIOS from "services/axios";

// utils
import { getConfig } from "utils/config";

export const getCategoryListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/stickers/get_stickers_category/1`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getLangListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/languages/list`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getBucketListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/get_bucket_list`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getAdminBucketListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/buckets/get_admin_bucket`, { config });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getPageListAPI = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(`v1/page/get_pages_for_feature`, {
      config,
    });
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const uploadDataAPI = async (
  language_id,
  thumbnail_url
) => {
  const response = { data: null, error: null };

  const token = localStorage.getItem("token") || null;
  const config = await getConfig();

  const formData = new FormData();
  formData.append("category_id", language_id);
  formData.append("image_file", thumbnail_url);
  try {
    const { data, error } = await AXIOS.post(
      `v1/stickers/upload_sticker`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/x-www-form-urlencoded",
        },
      }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const getUploadHistory = async () => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.get(
      `v1/stickers/get_admin_stickers`,
      { config }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const editCaptionFeatured = async (
  post_id,
  is_active,
  caption,
  page_id,
  language_id,
  state_id
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.put(
      `v1/posts/edit_uploaded_post`,
      { post_id, is_active, caption, page_id, language_id, state_id },
      { config }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const editSticker = async (
  sticker_id,
  active
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.post(
      `v1/stickers/edit_sticker?sticker_id=${sticker_id}&active=${active}`,
      {},
      { config }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};

export const addBucket = async (
  bucket_name,
  state_ids
) => {
  const response = { data: null, error: null };
  const config = await getConfig();
  try {
    const { data } = await AXIOS.post(
      `v1/buckets/add_bucket`,
      { bucket_name, state_ids },
      { config }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response.data.error;
  }
  return response;
};
