import React, { useEffect } from "react";
import { AuthContext } from "contexts/AuthContext";
import { Box } from "@mui/material";
import Loading from "pages/analytics/loader/LoadingAnim";
import CreateVideo from "./CreateVideo";
import FeaturedSidebar from "./FeaturedSidebar";
import AddEditBuckets from "./AddEditBuckets";
export const Featured = () => {
  const [report, setReport] = React.useState("1");
  const { setActivePage } = React.useContext(AuthContext);
  const handleOptionClick = (value) => {
    setReport(value);
  };
 
  useEffect(() => {
    setActivePage("Upload Content");
  }, []);
  
  return (
    <>
      <div style={{ display: "flex" }}>
      <FeaturedSidebar report={report} handleOptionClick={handleOptionClick} />
      <Box sx={{ width: "calc(100%)" }}>
        {report === "1" ? (
          <CreateVideo />
        )  : report === "2" ? (
          <AddEditBuckets/>
        )  : (
          <div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}><Loading/></div>
        )}
      </Box>
    </div>
    </>
  );
};
