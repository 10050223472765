import { AuthContext } from "contexts/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  addBucket,
  getBucketListAPI,
  getCategoryListAPI,
  getLangListAPI,
  getPageListAPI,
  getStateListAPI,
  uploadDataAPI,
} from "./actions";
import { AlertContext } from "contexts/AlertContext";
import StickerTable from "./StickerTable";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddEditSticker = () => {
  const { setActivePage } = React.useContext(AuthContext);
  const { showAlert } = useContext(AlertContext);
  const [langList, setLangList] = useState([]);
  const [langId, setLangID] = useState(-1);
  const [loading, setLoading] = useState(false);

  const [uploadedImage, setUploadedImage] = useState();
  const [tempPath, setTempPath] = useState();

  const getLangList = async () => {
    try {
      const { data, error } = await getCategoryListAPI();

      console.log(data);
      if (data === null) {
        setLangList([]);
      } else {
        setLangList(data.data);
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  const uploadVideo = async (
    language_id
  ) => {
    setLoading(true);
    try {
      const { data, error } = await uploadDataAPI(
        language_id,
        uploadedImage
      );

      if (error) {
        showAlert(error, "error");
      } else {
        setLangID(-1);
        setUploadedImage(null);
        showAlert("Uploaded Successfully!", "success");
        setTempPath(null);
      }
      setLoading(false);
    } catch (error) {
      showAlert(error, "error");
    }
  };

  const handleCapture = ({ target }) => {
    console.log(target.files[0]);
    setUploadedImage(target.files[0]);
    setTempPath(URL.createObjectURL(target.files[0]));
  };
  useEffect(() => {
    setActivePage("Upload Sticker");
  }, []);

  useEffect(() => {
    getLangList();
  }, []);
  const classes = useStyles();

  return (
    <Box
      sx={{
        height: "98vh",
        display: "flex",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <Box sx={{ width: "72vw", marginRight: 4 }}>
        <Box sx={{ marginBottom: "20px", marginTop: "40px" }}>
          <Typography sx={{ fontWeight: "700", fontSize: "2rem" }} variant="h6">
            Upload Sticker
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box
            sx={{
              height: 295,
              minWidth: 300,
              width: 300,
              border: "1px solid grey",
            }}
          >
            <img src={tempPath} style={{ objectFit: "contain" }} />
          </Box>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel>Select Category</InputLabel>
                  <Select
                    value={langId}
                    onChange={(e) => {
                      setLangID(e.target.value);
                    }}
                    label="Select Category"
                  >
                    <MenuItem key={-1} value={-1}>
                      Select Category
                    </MenuItem>
                    {langList.map((lang, index) => {
                     
                        return (
                          <MenuItem key={lang.id} value={lang.id}>
                            {lang.category_name}
                          </MenuItem>
                        );
                      
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", gap: 2, alignItems: "center" }}
              >
                <input
                  accept="image/*"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="raised-button-file"
                  onChange={handleCapture}
                  type="file"
                />
                <label htmlFor="raised-button-file">
                  <Button
                    variant="outlined"
                    component="span"
                    className={classes.button}
                  >
                    Upload Sticker Image
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    if (loading) {
                      return;
                    }
                    if (langId === -1 || uploadedImage === null) {
                      showAlert("Kindly fill all required fields!", "error");
                      return;
                    }
                    uploadVideo(langId);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {!loading ? "Submit" : "Uploading..."}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>

        <StickerTable
          langList={langList}
        />
      </Box>
    </Box>
  );
};

export default AddEditSticker;
