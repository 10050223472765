import React from "react";

// Routers
import { Route, Routes } from "react-router";
import PrivateRoute from "components/PrivateRoute";
import ProtectedRoute from "components/Protected";

// Pages
import AdminLayout from "components/layouts/admin.layout";
import TagVerification from "pages/tagVerification";
import UploadedPosts from "pages/uploadedPosts";
import Analytics from "pages/analytics";
import { Notification } from "pages/notification";
import { Featured } from "pages/featured_upload";
import AddEditSticker from "pages/sticker_upload/AddEditSticker";
import { Bucket } from "pages/featured_upload/Bucket";

// Lazy Components
const LoginView = React.lazy(() => import("pages/auth/login"));
const MainView = React.lazy(() => import("pages"));
const ErrorView = React.lazy(() => import("pages/error"));

const AllRouters = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginView />} />
        /* Private ROUTES: Only Super Loggedin can access */
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<MainView />} />
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="tagging" element={<UploadedPosts />} />
            <Route path="analytics" element={<Analytics />} />
            
            <Route path="notification" element={<Notification />} />
            /* PROTECTED ROUTES: Only Super AdminUser can access */
            <Route element={<ProtectedRoute />}>
              <Route path="tag-verification" element={<TagVerification />} />
              <Route path="upload-content" element={<Featured />} />
              <Route path="add-bucket" element={<Bucket />} />
              <Route path="upload-sticker" element={<AddEditSticker />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<ErrorView />} />
      </Routes>
    </>
  );
};

export default AllRouters;
